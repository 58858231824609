<template>


  <div class="col-12">
    <form class="form-horizontal" role="form" @submit.prevent="submitForm">
      <div class="row">
        <div class="col-12">
          <b-form-group id="platform" label-cols-sm="2" label-cols-lg="2" :label="$t('form.products.fields.titleField')"
            label-for="platform" :invalid-feedback="titleValidationMessage" :state="!isTitleValid ? false : null">
            <b-form-input id="platform" v-model="titleField" :state="!isTitleValid ? false : null"></b-form-input>
          </b-form-group>
        </div>
        <div class="col-6">
          <ModalSelectProductType :fieldLabel="$t('form.products.fields.categoryField')"
            :emptyLabel="$t('form.agent.labels.leaderEmpty')" @selectItem="selectProductTypeItem"
            @removeItem="removeProductTypeItem" :discardItems="selectProductType">
          </ModalSelectProductType>
          <br>
        </div>
        <div class="col-12">
          <ModalSelectCurrency :fieldLabel="$t('form.products.fields.currencyField')" @selectItem="selectCurrency"
            @removeItem="removeCurrency" :discardItems="this.selectedCurrencyList"></ModalSelectCurrency>
          <br v-if="selectedCurrencyList.length != 0">
          <div class="row" v-for="(currency, index) in this.selectedCurrencyList" :key="index">
            <div class="col-12">
              <ModalSelectPriceType :currency="currency"
                :fieldLabel="currency.name + ' ' + $t('form.products.fields.priceField')" @selectItem="selectPriceType"
                @removeItem="removePriceType" :discardItems="currency.selectedPriceTypes">
              </ModalSelectPriceType>
              <br v-if="currency.selectedPriceTypes.length != 0">
            </div>
            <div class="col-12" v-if="renderCurrencyComponent">
              <div class="row" v-for="(item, index) in currency.selectedPriceTypes" :key="index">
                <div class="col-3">
                  <b-form-group id="form.products.fields.priceField" label-cols-sm="6" label-cols-lg="6"
                    :label="item.title">
                    <b-form-input id="form.products.fields.priceField" type="number" v-model="item.value"
                      @keyup="reloadSelectedPriceTypes" :disabled="item.edit == false"></b-form-input>
                  </b-form-group>
                </div>
                <div class="col-4">
                  <ModalSelectTax v-if="renderComponent && item.taxEdit == true"
                    :fieldLabel="$t('form.products.labels.tax')" :currency="currency" :item="item"
                    :emptyLabel="$t('constants.NONE')" :discardItem="item" @selectItem="selectedTax"
                    @removeItem="removeTax"></ModalSelectTax>
                </div>
                <div class="col-3">
                  <b-form-group id="form.products.fields.priceField" label-cols-sm="6" label-cols-lg="6"
                    v-if="renderComponent" :label="$t('form.products.labels.priceWithoutTax')">
                    <b-form-input id="form.products.fields.priceField" :value="calculatePriceWithoutTax(item, currency)"
                      disabled></b-form-input>
                  </b-form-group>
                </div>
                <div class="col-2">
                  <b-form-group v-if="renderComponent" id="form.products.fields.priceTax" label-cols-sm="6"
                    label-cols-lg="6" :label="$t('form.products.labels.priceTax')">
                    <b-form-input id="form.products.fields.priceField" :value="calculatePriceTax(item, currency)"
                      disabled></b-form-input>
                  </b-form-group>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12">
          <hr>
          <ModalSelectCategory :fieldLabel="$t('form.products.fields.categoryField')" @selectItem="selectCategoryItem"
            @removeItem="removeCategoryItem" :discardItems="selectedCategory">
          </ModalSelectCategory>
          <hr>
        </div>
        <div class="col-12">
          <ModalSelectTag :fieldLabel="$t('form.products.fields.tagField')" @selectItem="selectTagItem"
            @removeItem="removeTagItem" :discardItems="selectedTag">
          </ModalSelectTag>
          <hr>
        </div>
        <div class="col-12">
          <ModalSelectBrand :fieldLabel="$t('form.products.fields.brandField')" @selectItem="selectBrandItem"
            @removeItem="removeBrandItem" :discardItems="selectedBrand">
          </ModalSelectBrand>
          <hr>
        </div>
        <div class="col-12">
          <b-form-group id="description" label-cols-sm="2" label-cols-lg="2"
            :label="$t('form.products.fields.descriptionField')" label-for="description"
            :invalid-feedback="descriptionValidationMessage" :state="!isDescriptionValid ? false : null">
            <ckeditor id="description" v-model="descriptionField" :editor="editor"
              :state="!isDescriptionValid ? false : null"></ckeditor>
          </b-form-group>
        </div>
      </div>
      <b-button variant="primary" type="submit" :disabled="formValidationButton">{{ $t('buttons.save')
        }}</b-button>
    </form>
  </div>
</template>

<script>

import CKEditor from "@ckeditor/ckeditor5-vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import ModalSelectCategory from "@/views/pages/category/modal-select.vue"
import ModalSelectTag from "@/views/pages/tags/modal-select.vue"
import ModalSelectBrand from "@/views/pages/brands/modal-select.vue"
import ModalSelectPriceType from "@/views/pages/product-price-type/modal-select.vue"
import ModalSelectTax from "@/views/pages/tax/modal-select.vue"
import ModalSelectProductType from "@/views/pages/product-type/modal-select.vue"
import ModalSelectCurrency from "@/views/pages/currency/modal-select.vue"
export default {
  components: {
    ckeditor: CKEditor.component,
    ModalSelectCategory,
    ModalSelectTag,
    ModalSelectBrand,
    ModalSelectPriceType,
    ModalSelectTax,
    ModalSelectProductType,
    ModalSelectCurrency
  },
  data() {
    return {
      titleField: null,
      isTitleValid: true,
      titleValidationMessage: null,

      descriptionField: null,
      isDescriptionValid: true,
      descriptionValidationMessage: null,

      selectedCategory: [],
      selectedTag: [],
      selectedBrand: [],
      selectedPriceTypes: [],
      selectProductType: [],
      selectedCurrencyList: [],
      renderComponent: true,
      renderCurrencyComponent: true,
      formValidationButton: false,
      editor: ClassicEditor,
    };
  },
  props: {
    item: {
      type: Object,
      default: () => ({
        title: {
          type: String,
          default: '',
        },
        description: {
          type: String,
          default: '',
        },
        is_active: {
          type: Boolean,
          default: true,
        },
      }),
    },
    type: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    }
  },
  watch: {
    'item'() {
      if (this.item) {
        if (this.item.title) {
          this.titleField = this.item.title
        }
        if (this.item.description) {
          this.descriptionField = this.item.description
        }
        if (this.item.categories) {
          this.selectedCategory = this.item.categories
        }
        if (this.item.tags) {
          this.selectedTag = this.item.tags
        }
        if (this.item.brands) {
          this.selectedBrand = this.item.brands
        }
        if (this.item.type) {
          this.selectProductType = [this.item.type]
        }

        if (this.item.prices) {
          for (let i = 0; i < this.item.prices.length; i++) {
            if (!this.selectedCurrencyList.some(item => item.id === this.item.prices[i].currency.currency.id)) {
              this.item.prices[i].currency.currency.selectedPriceTypes = []
              this.selectedCurrencyList.push(this.item.prices[i].currency.currency)
            }
          }
          for (let i = 0; i < this.selectedCurrencyList.length; i++) {
            for (let b = 0; b < this.item.prices.length; b++) {
              if (this.selectedCurrencyList[i].id == this.item.prices[b].currency.currency.id) {
                var c = {}
                c = this.item.prices[b].type
                c.value = this.item.prices[b].value
                c.tax = this.item.prices[b].tax
                c.position = this.item.prices[b].type.position
                this.selectedCurrencyList[i].selectedPriceTypes.push(c)
                this.selectedCurrencyList[i].selectedPriceTypes.sort((a, y) => { return y.position < a.position; })
              }
            }
          }
        }
        console.log(this.selectedCurrencyList)


        this.$nextTick(() => {
          this.renderComponent = true;
        });
      }
      this.validateForm();
    },
  },
  methods: {
    reloadSelectedPriceTypes() {
      this.renderComponent = false
      for (let b = 0; b < this.selectedCurrencyList.length; b++) {
        for (let i = 0; i < this.selectedCurrencyList[b].selectedPriceTypes.length; i++) {
          if (this.selectedCurrencyList[b].selectedPriceTypes[i].calculation) {
            var t1 = this.findSelectedPriceById(this.selectedCurrencyList[b].selectedPriceTypes[i].calculation.t1, this.selectedCurrencyList[b])
            var t2 = this.findSelectedPriceById(this.selectedCurrencyList[b].selectedPriceTypes[i].calculation.t2, this.selectedCurrencyList[b])
            if (this.selectedCurrencyList[b].selectedPriceTypes[i].calculation.operator.type == 'ADD') {
              this.selectedCurrencyList[b].selectedPriceTypes[i].value = parseFloat(t1.value) + parseFloat(t2.value)
            } else {
              this.selectedCurrencyList[b].selectedPriceTypes[i].value = parseFloat(t1.value) - parseFloat(t2.value)
            }
          }
          this.selectedCurrencyList[b].selectedPriceTypes.sort((a, b) => { return b.position < a.position; })
          this.$nextTick(() => {
            this.renderComponent = true;
          });
        }
      }
    },
    findSelectedPriceById(item, currency) {
      for (let j = 0; j < this.selectedCurrencyList.length; j++) {
        if (this.selectedCurrencyList[j].id == currency.id) {
          for (let i = 0; i < this.selectedCurrencyList[j].selectedPriceTypes.length; i++) {
            if (this.selectedCurrencyList[j].selectedPriceTypes[i].id == item.id) {
              return this.selectedCurrencyList[j].selectedPriceTypes[i]
            }
          }
        }
      }
    },
    calculatePriceWithoutTax(item, currency) {
      if (item.tax) {
        var tax = parseInt(item.value) / (1 + (parseInt(item.tax.percent) / 100))
        return tax.toFixed(2)
      } else {
        return item.value
      }
    },
    calculatePriceTax(item, currency) {
      for (let j = 0; j < this.selectedCurrencyList.length; j++) {
        if (this.selectedCurrencyList[j].id == currency.id) {
          if (item.taxEdit == false && item.taxSumCaluclation == true) {
            var t1 = 0.0
            var t2 = 0.0
            for (let i = 0; i < this.selectedCurrencyList[j].selectedPriceTypes.length; i++) {
              if (this.selectedCurrencyList[j].selectedPriceTypes[i].id == item.calculation.t1.id) {
                t1 = this.calculatePriceTax(this.selectedCurrencyList[j].selectedPriceTypes[i], currency)
              }
              if (this.selectedCurrencyList[j].selectedPriceTypes[i].id == item.calculation.t2.id) {
                t2 = this.calculatePriceTax(this.selectedCurrencyList[j].selectedPriceTypes[i], currency)
              }
            }
            if (item.calculation.operator.type == "ADD") {
              return (parseFloat(t1) + parseFloat(t2)).toFixed(2)
            } else {
              return (parseFloat(t1) - parseFloat(t2)).toFixed(2)
            }
          } else {
            if (item.tax) {
              var tax = parseFloat(item.value) / (1 + (parseFloat(item.tax.percent) / 100))
              var r = item.value - tax
              return r.toFixed(2)
            } else {
              return 0.0
            }
          }
        }
      }
    },
    selectedTax(item, currency) {
      this.renderComponent = false
      for (let i = 0; i < this.selectedCurrencyList.length; i++) {
        if (this.selectedCurrencyList[i].id == currency.id) {
          for (let j = 0; j < this.selectedCurrencyList[i].selectedPriceTypes.length; j++) {
            if (this.selectedCurrencyList[i].selectedPriceTypes[j].id == item.id) {
              this.selectedCurrencyList[i].selectedPriceTypes[j] = item
            }
          }
          this.selectedCurrencyList[i].selectedPriceTypes.sort((a, b) => { return b.position < a.position; })
          this.$nextTick(() => {
            this.renderComponent = true;
          });
        }
      }
    },
    removeTax(item, currency) {
      this.renderComponent = false
      for (let i = 0; i < this.selectedCurrencyList.length; i++) {
        if (this.selectedCurrencyList[i].id == currency.id) {
          for (let j = 0; j < this.selectedCurrencyList[i].selectedPriceTypes.length; j++) {
            if (this.selectedCurrencyList[i].selectedPriceTypes[j].id == item.id) {
              this.selectedCurrencyList[i].selectedPriceTypes[j] = item
            }
          }
          this.selectedCurrencyList[i].selectedPriceTypes.sort((a, b) => { return b.position < a.position; })
          this.$nextTick(() => {
            this.renderComponent = true;
          });
        }
      }
    },
    checkSelectedPriceType(item, currency) {
      for (let i = 0; i < this.selectedCurrencyList.length; i++) {
        if (this.selectedCurrencyList[i].id == currency.id) {
          for (let j = 0; j < this.selectedCurrencyList[i].selectedPriceTypes.length; j++) {
            if (item.id == this.selectedCurrencyList[i].selectedPriceTypes[j].id) {
              return true
            }
          }
        }
      }
      return false
    },
    selectPriceType(item, currency) {
      this.renderCurrencyComponent = false
      item.value = 0
      for (let i = 0; i < this.selectedCurrencyList.length; i++) {
        if (this.selectedCurrencyList[i].id == currency.id) {
          this.selectedCurrencyList[i].selectedPriceTypes.push(item)
          if (item.calculation) {
            if (item.calculation.t1) {
              if (!this.checkSelectedPriceType(item.calculation.t1, currency)) {
                item.calculation.t1.value = 0
                this.selectedCurrencyList[i].selectedPriceTypes.push(item.calculation.t1)
              }
            }
            if (item.calculation.t2) {
              if (!this.checkSelectedPriceType(item.calculation.t2, currency)) {
                item.calculation.t2.value = 0
                this.selectedCurrencyList[i].selectedPriceTypes.push(item.calculation.t2)
              }
            }
          }
          this.selectedCurrencyList[i].selectedPriceTypes.sort((a, b) => { return b.position < a.position; })
          this.$nextTick(() => {
            this.renderCurrencyComponent = true;
          });
        }
      }
    },
    removePriceType(item, currency) {
      this.renderCurrencyComponent = false
      for (let i = 0; i < this.selectedCurrencyList.length; i++) {
        if (this.selectedCurrencyList[i].id == currency.id) {
          for (let j = 0; j < this.selectedCurrencyList[i].selectedPriceTypes.length; j++) {
            if (item.id == this.selectedCurrencyList[i].selectedPriceTypes[j].id) {
              this.selectedCurrencyList[i].selectedPriceTypes.splice(i, 1);
            }
          }
          this.selectedCurrencyList[i].selectedPriceTypes.sort((a, b) => { return b.position < a.position; })
          this.$nextTick(() => {
            this.renderCurrencyComponent = true;
          });
        }
      }
    },
    selectCurrency(item) {
      item.selectedPriceTypes = []
      this.selectedCurrencyList.push(item)
    },
    removeCurrency(item) {
      for (let i = 0; i < this.selectedCurrencyList.length; i++) {
        if (item.id == this.selectedCurrencyList[i].id) {
          this.selectedCurrencyList.splice(i, 1);
          break
        }
      }
    },
    selectCategoryItem(item) {
      this.selectedCategory.push(item)
    },
    removeCategoryItem(item) {
      for (let i = 0; i < this.selectedCategory.length; i++) {
        if (item.id == this.selectedCategory[i].id) {
          this.selectedCategory.splice(i, 1);
          break
        }
      }
    },
    selectProductTypeItem(item) {
      this.selectProductType = []
      this.selectProductType.push(item)
    },
    removeProductTypeItem(item) {
      for (let i = 0; i < this.selectProductType.length; i++) {
        if (item.id == this.selectProductType[i].id) {
          this.selectProductType.splice(i, 1);
          break
        }
      }
    },
    selectTagItem(item) {
      this.selectedTag.push(item)
    },
    removeTagItem(item) {
      for (let i = 0; i < this.selectedTag.length; i++) {
        if (item.id == this.selectedTag[i].id) {
          this.selectedTag.splice(i, 1);
          break
        }
      }
    },
    selectBrandItem(item) {
      this.selectedBrand.push(item)
    },
    removeBrandItem(item) {
      for (let i = 0; i < this.selectedBrand.length; i++) {
        if (item.id == this.selectedBrand[i].id) {
          this.selectedBrand.splice(i, 1);
          break
        }
      }
    },
    validateForm() {

    },
    submitForm() {
      var data = {}
      data.title = this.titleField
      data.description = this.descriptionField
      data.type = this.selectProductType[0]
      data.categories = this.selectedCategory
      data.tags = this.selectedTag
      data.brands = this.selectedBrand
      data.prices = []


      for (let b = 0; b < this.selectedCurrencyList.length; b++) {
        console.log(this.selectedCurrencyList[b].selectedPriceTypes)
        for (let i = 0; i < this.selectedCurrencyList[b].selectedPriceTypes.length; i++) {
          console.log(this.selectedCurrencyList[b].selectedPriceTypes[i])
          var g = {}

          g.type = this.selectedCurrencyList[b].selectedPriceTypes[i]
          g.value = this.selectedCurrencyList[b].selectedPriceTypes[i].value
          g.tax = this.selectedCurrencyList[b].selectedPriceTypes[i].tax

          const clone = JSON.parse(JSON.stringify(this.selectedCurrencyList[b]));

          clone.selectedPriceTypes = null

          g.currency = clone

          data.prices.push(g)
        }
      }
      const json = JSON.stringify(data);
      this.$emit('data', json);
    },
  },
  mounted() {
    // if (this.type == "CREATE") {
    //   this.isTitleValid = true
    //   this.isDescriptionValid = true
    //   this.titleValidationMessage = ""
    //   this.descriptionValidationMessage = ""
    //   this.formValidationButton = true
    // } else {
    //   this.validateForm()
    // }
  }
};
</script>